import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	TextContainer,
	Ul,
	Paragraph,
	H4,
	Icon,
	IconDelete,
	IconEdit,
	IconOptions,
	IconOpenNew,
	IconSettings,
	IconFullscreen,
	IconContrast,
} from "@siteimprove/fancylib";
import { SpaceSmall } from "@siteimprove/fancylib/design-tokens/variables";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionMenu } from "./action-menu";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Action menu",
	notepad: "https://hackmd.io/d6TpHmYiS6eGp1r7N_WQhw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Action menu"
			subTitle="Action Menu is used to present a list of actions when space is limited."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ActionMenu} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					An <Code>ActionMenu</Code> allows the user to choose a specific action from a list of
					actions. When the <Code>ActionMenu</Code> button is pressed, a list of items for the
					current task or view appears. The user can only select one item at a time. When an item is
					pressed, the action is performed immediately and the menu is closed.
				</Paragraph>
				<Paragraph>
					The <Code>ActionMenu</Code> consists of four main elements:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Button</b>: communicates what actions are available and enable users to trigger a
							menu. The label never changes. A button is paired with the menu and is part of the
							component architecture.{" "}
						</>,
						<>
							<b>Chevron icon</b>: indicates that more actions are available.
						</>,
						<>
							<b>Menu</b>: a list of actions the user can take. The menu can appear above or below a
							button, depending on where a menu appears in the layout, so that it always remains
							visible.
						</>,
						<>
							<b>Menu item</b>: describes the action that the user can take from the menu. Place the
							most frequently used or high priority items at the top.
						</>,
					]}
				/>

				<Example fn={BasicUsage} />
				<Header.H3>Item variants</Header.H3>
				<Paragraph>Each item in a menu can be either a button or a link.</Paragraph>
				<Ul
					items={[
						<>
							<b>Button items</b>: a menu item wrapped in a button tag <Code>{`<button>`}</Code>. It
							is useful when the user's main task is to change the state of some data set by issuing
							commands.{" "}
						</>,
						<>
							<b>Link items</b>: a menu item wrapped in an anchor tag <Code>{`<a>`}</Code>. It is
							useful when the user's main task is to obtain information by navigating to another
							site, page or section of a page.
						</>,
					]}
				/>
				<Paragraph>
					The menu item always has a label and can optionally include descriptions, icons, and
					tooltips to give the user additional information about an action. Keep items concise and
					consistent.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Label</b>: describes the action the user may select. The label should be direct and
							clear so the user can quickly decide on an action.{" "}
						</>,
						<>
							<b>Description</b>: use descriptions when the information will help the user to choose
							an action from the menu. Do not overload the user with repetitive information.
						</>,
						<>
							<b>Icon</b>: use an icon only when necessary and when it has a strong association with
							the item's label.
						</>,
						<>
							<b>Tooltip</b>: use tooltips sparingly, as they only work when hovering. Excessive use
							of tooltips can clutter UI and obscure the label of the item.
						</>,
					]}
				/>
				<H4>State variations</H4>
				<Paragraph>Each item has the following states:</Paragraph>
				<Ul
					items={[
						<>
							<b>Default</b>: when a user has not interacted with an item, it is displayed in its
							default state.{" "}
						</>,
						<>
							<b>Hover</b>: when a user hovers over an item, a highlight (light gray background)
							appears indicating that the item is selectable.
						</>,
						<>
							<b>Focus</b>: a blue outline appears around the text area when the user uses keyboard
							to navigate through the items.
						</>,
						<>
							<b>Active/Pressed</b>: a light blue background appears while the item is clicked.
						</>,
						<>
							<b>Disabled</b>: the disabled option should be used infrequently. It indicates that
							the item is present, but is not currently available to the user.
						</>,
					]}
				/>

				<Example fn={ItemVariantsExample} />
				<Header.H3>Usage with Sections</Header.H3>
				<Paragraph>
					It is useful to use sections to differentiate between the functions and relationships of
					menu items. For example, add, edit, and copy can be considered as one section. Avoid
					having only one section in a menu.
				</Paragraph>
				<Paragraph>A menu section consists of a subheading, an icon and a divider.</Paragraph>
				<Ul
					items={[
						<>
							<b>Subheading</b>: describes the group of actions listed below.{" "}
						</>,
						<>
							<b>Icon</b> (optional): provides a visual representation of the subheading, such as a
							gear that indicates settings.
						</>,
						<>
							<b>Divider</b> (optional): visually groups related actions within the same menu.
						</>,
					]}
				/>

				<Example fn={SectionsUsage} />
				<Header.H3>Button variants</Header.H3>
				<Paragraph>
					Choose an appropriate <Code>Button</Code> that can help streamline the user's choices. In
					certain cases, you may use different <Code>Button</Code> variants in your layout. Be
					cautious when mixing different variants in the same layout to avoid inconsistency. See{" "}
					<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink> to find
					more details about the button variants.
				</Paragraph>

				<Example fn={ButtonVariantsUsage} />
				<Header.H3>Chevron and icons are optional</Header.H3>
				<Paragraph>
					The icon-only <Code>Button</Code>, especially when using <Code>IconOptions</Code> (three
					horizontal dots), is often used as a trigger for hidden items that do not fit in views.
					Avoid icons that could be confused with navigation, such as <Code>IconMenu</Code> in the
					icon-only <Code>Button</Code>.
				</Paragraph>

				<Example fn={IconsAndChevronUsage} />
				<Header.H3>Searchable</Header.H3>
				<Paragraph>
					The search field allows the user to type in a keyword to search for an item, and it should
					only be used if the user is very familiar with the menu item. This way the user can access
					it quickly.
				</Paragraph>
				<Paragraph>
					You should keep in mind that since menu items should have fewer than seven items, it might
					be unusual to include a search function in the <Code>ActionMenu</Code>. Alternatively, if
					you use sections properly, the menu should be easy to scan.
				</Paragraph>

				<Example fn={SearchableUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={ActionMenu}
					initialProps={{
						items: [
							{ text: "Actionbutton", onClick: () => console.log("clicked") },
							{ text: "ActionLink", href: "https://www.google.com", openNew: true },
							ActionMenu.divider,
							{
								text: "Disabled actionbutton",
								onClick: () => console.log("clicked"),
								disabled: true,
							},
							{
								text: "ActionLink",
								href: "https://www.google.com",
								openNew: true,
							},
							ActionMenu.divider,
							{
								text: "Actionbutton",
								onClick: () => console.log("clicked"),
							},
						],
						buttonContent: "Action Menu",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>ActionMenu</Code> when{" "}
					</Paragraph>
					<Ul
						items={[
							<>the user can perform actions with a single selection.</>,
							<>less important actions need to be provided and space is limited.</>,
							<>there is more than one action or link, preferably 2 to 7.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>ActionMenu</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<H4>Card</H4>
								An <Code>ActionMenu</Code> is often used in a <Code>Card</Code> to provide
								additional actions, such as exporting files or adding widgets to the dashboard. An
								example can be found in{" "}
								<a href="https://my2.siteimprove.com/Accessibility/877948/NextGen/Overview?pageSegments=&conformance=&siteGoal=&lang=en-US">
									{`Main Menu > Accessibility > Accessibility Overview > Add widget to dashboard`}
								</a>
								.
							</>,
							<>
								<H4>Table</H4>
								An <Code>ActionMenu</Code> is commonly used in a <Code>Table</Code> to provide the
								user with a set of contextual actions related to their current view or task. These
								actions often provide relevant tools for completing tasks without cluttering the UI.
								If the menu contains items such as delete, warn the user that the action would
								destroy data. An example can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FPolicy%2F16111846137%2FContentPolicies%2FIndex%2F1%2FChangedDate%2FDesc%3FpinnedOnly%3Dfalse%26pageSize%3D200%26policies-tab%3D0%26lang%3Den-US">
									{`Main Menu > Policy > My Policies `}
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Menu items</Header.H4>
					<Ul
						items={[
							<>
								Include only a set of actions or frequent actions that relate to the task at hand.
							</>,
							<>
								List menu items in the order in which they are most frequently used so that the user
								can focus on the most important action.
							</>,
							<>
								Limit the number of items within a menu. Keep the length of the menu manageable and
								ensure that all items are visible without having to scroll.
							</>,
							<>
								Disable items that are not relevant to the user's context to prevent them from being
								triggered.
							</>,
						]}
					/>
					<Header.H4>Icons</Header.H4>
					<Ul
						items={[
							<>
								Use action icons for button items, such as <Code>IconEdit</Code>, and{" "}
								<Code>IconCopy</Code>.
							</>,
							<>
								Be careful not to use the same icon for similar actions in the menu, which does not
								make it easier for the user to select an item.{" "}
								<>
									For example, the menu could contain related actions that allow the user to
									download data in different formats, such as in PDF and HTML formats. In this case,{" "}
									use the <Code>IconDownload</Code> icon for the icon-only <Code>Button</Code> to
									the related actions, and not in each of the menu items.
								</>
							</>,
							<>
								If you cannot find an appropriate icon that represents the action, it is recommended
								to not have an icon for the menu item.
							</>,
							<>Icons should always be placed to the left of the menu item.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>A button is usually located at the right edge of a card, a table or a page.</>,
							<>
								A the destructive item, that would destroy the user's data, should always be
								visually separated and located at the bottom of the menu. It is recommended to use{" "}
								<Code>$color--red--dark</Code> (#D04257) to indicate a destructive item.
							</>,
							<>
								To maintain a consistent single-column format, the default popover has a minimum
								width of 15 rem and a maximum width of 30 rem.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>The user cannot perform an action.</>,
							<>
								There is space for displaying the actions. Especially if the menu contains only one
								item, consider displaying it as a single action if possible.
							</>,
							<>
								The user can select more than one item from a list where an item is an option in a
								filter, form or modal. Use{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink> instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							<>
								Ensure that all labels are clear, concise and consistent. If the same item is
								available in an additional place, make sure the label is consistent.
							</>,
							<>
								Ensure that icon-only <Code>Button</Code> has a name that is accessible to readers.
							</>,
							<>
								Ensure that the user can open and close the menu with the keyboard by pressing the
								button and navigate the items within the menu.
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Header.H4>Button label</Header.H4>
					<Paragraph>
						The button is used as a trigger paired with an <Code>ActionMenu</Code>. Avoid verbs that
						imply that pressing a button will perform an action. The button does not perform an
						action; the items in the <Code>ActionMenu</Code> perform the actions. For example, write
						"More" instead of "Edit" for the button label.{" "}
					</Paragraph>
					<Header.H4>Menu item</Header.H4>
					<Ul
						items={[
							<>Use verb + object for item labels: “Add widget,” or “Edit policy”. </>,
							<>Use clear and concise labels, preferably 1-3 words.</>,
							<>Use sentence case for a label.</>,
							<>Do not add punctuation after a label. </>,
							<>Avoid unnecessary words and articles such as "the", "an", or "a".</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<ActionMenu
		items={[
			{ text: "Action Button", onClick: () => console.log("clicked") },
			{ text: "Action Link", href: "https://www.google.com", openNew: true },
			ActionMenu.divider,
			{
				text: "Disabled Action Button",
				onClick: () => console.log("clicked"),
				disabled: true,
			},
			{
				text: "Disabled Action Link",
				href: "https://www.google.com",
				openNew: true,
				disabled: true,
			},
		]}
		buttonContent="Action Menu"
	/>
);

const ItemVariantsExample = () => (
	<>
		<ActionMenu
			buttonContent="Button items"
			items={[
				{ text: "Action Button", onClick: () => console.log("clicked") },
				{
					text: "Action Button Disabled",
					onClick: () => console.log("clicked"),
					disabled: true,
				},
				ActionMenu.divider,
				{
					text: "Action Button With Icon",
					onClick: () => console.log("clicked"),
					icon: <IconSettings />,
				},
				{
					text: "Action Button With Description",
					description: "This button has a description",
					onClick: () => console.log("clicked"),
				},
				{
					text: "Action Button With Tooltip",
					description: "This button has a description",
					icon: <IconSettings />,
					tooltip: "This button has a tooltip",
					onClick: () => console.log("clicked"),
				},
			]}
		/>
		<br />
		<br />
		<ActionMenu
			buttonContent="Link items"
			items={[
				{ text: "Action Link", href: "https://www.google.com", openNew: true },
				{
					text: "Action Link Disabled",
					href: "https://www.google.com",
					openNew: true,
					disabled: true,
				},
				ActionMenu.divider,
				{
					text: "Action Link With Icon",
					href: "https://www.google.com",
					openNew: true,
					icon: <IconSettings />,
				},
				{
					text: "Action Link With Description",
					href: "https://www.google.com",
					openNew: true,
					icon: <IconSettings />,
					description: "This link has a description and an icon",
				},
				{
					text: "Action Link With Tooltip",
					href: "https://www.google.com",
					openNew: true,
					icon: <IconSettings />,
					description: "This link has a description and an icon",
					tooltip: "This link has a tooltip",
				},
			]}
		/>
	</>
);

const IconsAndChevronUsage = () => (
	<ActionMenu
		aria-label="Action menu"
		hideChevron
		buttonContent={
			<Icon>
				<IconOptions />
			</Icon>
		}
		items={[
			{ text: "Actionbutton", icon: <IconEdit />, onClick: () => console.log("clicked") },
			{ text: "ActionLink", icon: <IconOpenNew />, href: "https://www.google.com", openNew: true },
			{
				text: "Disabled actionbutton",
				icon: <IconDelete />,
				onClick: () => console.log("clicked"),
				disabled: true,
			},
			ActionMenu.divider,
			{ text: "Actionbutton", onClick: () => console.log("clicked") },
			{ text: "ActionLink", href: "https://www.google.com", openNew: true },
			{
				text: "Disabled actionbutton",
				onClick: () => console.log("clicked"),
				disabled: true,
			},
		]}
	/>
);

const SectionsUsage = () => (
	<ActionMenu
		buttonContent="Action Menu"
		items={[
			// without icons
			ActionMenu.sectionHeader("File"),
			{ text: "New File", onClick: () => console.log("clicked") },
			{ text: "Open File", onClick: () => console.log("clicked") },
			// with icons
			ActionMenu.sectionHeader("Settings", <IconSettings />),
			{
				text: "Increase Contrast",
				icon: <IconContrast />,
				onClick: () => console.log("clicked"),
			},
			{
				text: "Toggle Fullscreen",
				description: "this is a description",
				icon: <IconFullscreen />,
				onClick: () => console.log("clicked"),
			},
		]}
	/>
);

const ButtonVariantsUsage = () => {
	const items = [
		{ text: "Action Button", onClick: () => console.log("clicked") },
		{ text: "Action Link", href: "https://www.google.com", openNew: true },
	];
	return (
		<div style={{ display: "flex", gap: SpaceSmall }}>
			<ActionMenu
				items={items}
				buttonContent="Action Menu"
				buttonProps={{
					size: "small",
					variant: "primary",
				}}
			/>
			<ActionMenu
				items={items}
				buttonContent="Action Menu"
				buttonProps={{
					size: "medium",
					variant: "secondary",
				}}
			/>
			<ActionMenu
				items={items}
				buttonContent="Action Menu"
				buttonProps={{
					size: "large",
					variant: "ctaDefault",
				}}
			/>
		</div>
	);
};

const SearchableUsage = () => (
	<ActionMenu
		searchable
		buttonContent="Action Menu"
		items={[
			// without icons
			ActionMenu.sectionHeader("File"),
			{ text: "New File", onClick: () => console.log("clicked") },
			{ text: "Open File", onClick: () => console.log("clicked") },
			// with icons
			ActionMenu.sectionHeader("Settings", <IconSettings />),
			{
				text: "Increase Contrast",
				icon: <IconContrast />,
				onClick: () => console.log("clicked"),
			},
			{
				text: "Toggle Fullscreen",
				description: "this is a description",
				icon: <IconFullscreen />,
				onClick: () => console.log("clicked"),
			},
		]}
	/>
);
