import React, { ReactNode } from "react";
import {
	cn,
	f2u,
	DataObserveKey,
	VisualComponent,
	Divider,
	Icon,
	InlineText,
	Tooltip,
	TooltipProps,
} from "@siteimprove/fancylib";
import { Popover, DropdownButtonProps } from "../../overlay/popover/popover";
import { BaseInteractiveList } from "../base-interactive-list/base-interactive-list";
import { DropdownSectionHeader } from "../dropdown-section-header/dropdown-section-header";
import { assignKnobName } from "../../../utils/documentation-utils";
import { useSearchableList } from "../../../utils/hooks";
import { useLabTranslations } from "../../../translations/translations";
import { InputField } from "../../forms-and-inputs/input-field/input-field";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import * as scss from "./action-menu.scss";

export type ActionMenuProps = {
	/** Label to describe the list */
	"aria-label"?: string;
	/** List of strings or objects to display in the menu */
	items: ActionMenuItem[];
	/** Text and other content for menu menu */
	buttonContent: ReactNode;
	/** Props to affect the styling of the menu button */
	buttonProps?: DropdownButtonProps;
	/** If set to true, chevron icon will be hidden */
	hideChevron?: boolean;
	/** Classname for container of both menu and popover */
	containerClassName?: string;
	/** Enables item search functionality */
	searchable?: boolean;
	/** Tooltip text */
	tooltip?: TooltipProps["content"];
	/** Tooltip placement */
	tooltipPlacement?: TooltipProps["placement"];
} & DataObserveKey &
	VisualComponent;

type OptionItem = ActionItem | LinkItem;
export type ActionMenuItem = OptionItem | Divider | SectionHeader;

interface SharedProps {
	text: string;
	description?: string;
	icon?: JSX.Element;
	disabled?: boolean;
	tooltip?: string;
}

type ActionItem = { onClick: () => void } & SharedProps & DataObserveKey;

type LinkItem = { href: string; openNew?: boolean } & SharedProps & DataObserveKey;

type Divider = { isDivider: true };

type SectionHeader = Omit<SharedProps, "disabled"> & { isSectionHeader: true }; // You can't really "disable" a section header

function navigateTo(url: string, options: { openNew?: boolean }) {
	const { openNew = false } = options;

	if (openNew) {
		window.open(url, "_blank", "noopener");
	} else {
		location.href = url;
	}
}

function InternalActionMenu(props: ActionMenuProps) {
	const {
		items: allItems,
		buttonContent,
		buttonProps = {},
		hideChevron,
		containerClassName,
		searchable,
		tooltip,
		tooltipPlacement,
		...rest
	} = props;

	const [query, setQuery] = React.useState("");
	const items = useSearchableList<ActionMenuItem>(allItems, query, stringifyItem);
	const i18nLab = useLabTranslations();

	if (isItemDivider(items[0]) || isItemDivider(items[items.length - 1])) {
		throw new Error("First and last item in an ActionMenu cannot be a Divider");
	}

	const noIcons = items.filter((item) => !("isDivider" in item) && item.icon).length === 0;

	// Add dividers before headers. We do this, so the dividers are in their own <li> elements,
	// which semantically makes more sense and makes padding automatically consistent.
	// 	`i` starts at `1`, so we don't add a divider above a header if it's the very first item.
	for (let i = 1; i < items.length; i++) {
		if ("isSectionHeader" in items[i]) {
			items.splice(i, 0, { isDivider: true }); // <-- insert divider at index
			i++; // <-- everything shifted, so we skip `i` forward to catch up.
		}
	}

	return (
		<Popover
			{...rest}
			aria-haspopup="menu"
			data-component="action-menu"
			containerClassName={containerClassName}
			buttonProps={buttonProps}
			placement="bottom-end"
			tooltip={tooltip}
			tooltipPlacement={tooltipPlacement}
			popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
				<>
					{searchable && (
						<InputField
							aria-autocomplete="list"
							aria-controls={id}
							aria-expanded={true}
							aria-label="Search"
							autoFocus
							className={cn(scss.searchInputField)}
							onChange={setQuery}
							placeholder={i18nLab.search}
							type="search"
							ref={firstFocusableRef}
							value={query}
						/>
					)}
					<BaseInteractiveList
						listId={id}
						listRef={searchable ? undefined : firstFocusableRef}
						listClassName={scss.actionMenuList}
						liClassName={(item) => {
							if ("isDivider" in item) return scss.divider;
						}}
						items={items}
						itemRenderer={(item) => actionItemRenderer(item, noIcons, query)}
						role="menu"
						optionRole="menuitem"
						aria-label={props["aria-label"]}
						onItemSelect={(item) => {
							if (!isOptionItem(item) || item.disabled) return;
							if ("onClick" in item) {
								item.onClick();
								setIsOpen(false);
							} else if ("href" in item) {
								navigateTo(item.href, { openNew: item.openNew });
								setIsOpen(false);
							}
						}}
						isNotAnOption={(item) => !isOptionItem(item)}
						isDisabled={(item) => "disabled" in item && !!item.disabled}
						stringify={stringifyItem}
					/>
				</>
			)}
			hideChevron={hideChevron}
			buttonContent={buttonContent}
		/>
	);
}

const actionItemRenderer = (item: ActionMenuItem, noIcons: boolean, needle: string) => {
	if ("isDivider" in item) {
		return <Divider />;
	}

	const maybeIcon = !noIcons && item.icon && (
		<span className={scss.icon}>
			<Icon size="medium">{item.icon}</Icon>
		</span>
	);

	if ("isSectionHeader" in item) {
		return <DropdownSectionHeader text={item.text} beforeText={maybeIcon} />;
	}

	const actionContent = (
		<div className={cn(scss.actionMainContent)}>
			<div className={cn(scss.titleContent)}>
				{maybeIcon}
				<InlineText lineHeight="multi-line" tone={item.disabled ? "subtle" : "neutralDark"}>
					<TextHighlight value={item.text} needle={needle} caseSensitive={false} />
				</InlineText>
			</div>
			<div>
				{item.description && (
					<InlineText size="xSmall" tone="subtle" lineHeight="multi-line">
						{item.description}
					</InlineText>
				)}
			</div>
		</div>
	);

	const renderAction = () => {
		if ("href" in item) {
			return (
				<a
					className={cn(scss.action, scss.link, item.disabled && scss.disabled)}
					data-observe-key={item["data-observe-key"]}
					href={item.href}
					onClick={(e) => e.preventDefault()}
					rel={f2u(item.openNew && "noopener")}
					tabIndex={-1}
					target={f2u(item.openNew && "_blank")}
				>
					{actionContent}
				</a>
			);
		}

		if ("onClick" in item) {
			return (
				<button
					className={cn(scss.action, scss.button, item.disabled && scss.disabled)}
					data-observe-key={item["data-observe-key"]}
					disabled={item.disabled}
					tabIndex={-1}
					type="button"
				>
					{actionContent}
				</button>
			);
		}
	};

	return (
		<>
			{item.tooltip ? (
				<Tooltip
					variant={{ type: "interactive" }}
					content={item.tooltip}
					className={cn(scss.tooltip)}
				>
					{renderAction()}
				</Tooltip>
			) : (
				renderAction()
			)}
		</>
	);
};

assignKnobName(InternalActionMenu);
InternalActionMenu.displayName = "ActionMenu";

function isItemDivider(item: ActionMenuItem): boolean {
	return typeof item === "object" && "isDivider" in item;
}

const divider = { isDivider: true } as const;

const sectionHeader = (text: string, icon?: JSX.Element) =>
	({ text, icon, isSectionHeader: true } as const);

function isOptionItem(x: ActionMenuItem): x is OptionItem {
	return !("isDivider" in x || "isSectionHeader" in x);
}

function stringifyItem(item: ActionMenuItem): string {
	if (isOptionItem(item)) return item.text;
	return "";
}

export const ActionMenu = Object.assign(InternalActionMenu, { divider, sectionHeader });
