import * as React from "react";
import { InlineText } from "@siteimprove/fancylib";
import { isNullOrUndefined } from "../../../utils/shorthands";
import * as scss from "./dropdown-section-header.scss";

type DropdownSectionHeaderProps = {
	/** Text that will populate the dropdown section */
	text: string;
	/** Node that will display before text content */
	beforeText?: React.ReactNode;
};

export type SectionHeader = {
	isSectionHeader: true;
} & DropdownSectionHeaderProps;

export function DropdownSectionHeader(props: DropdownSectionHeaderProps): JSX.Element {
	return (
		<InlineText emphasis="medium" tone="subtle" className={scss.sectionHeader}>
			{props.beforeText}
			{props.text}
		</InlineText>
	);
}

export const isSectionHeader = (x: unknown): x is SectionHeader =>
	!isNullOrUndefined(x) &&
	typeof x === "object" &&
	x !== null &&
	"isSectionHeader" in x &&
	"isSectionHeader" in x === true;

export const sectionHeader = (text: string, beforeText?: React.ReactNode): SectionHeader => ({
	isSectionHeader: true as const,
	text,
	beforeText,
});
